import '@babel/polyfill';
import '@babel/plugin-syntax-dynamic-import';
import './polyfill/arrayFrom';
import './polyfill/arrayFind';
import './polyfill/dataset';
import './polyfill/intersection-observer';
import Expander from './modules/expander';
import QuickAccess from './modules/quickAccess';

(function(window) {

    if ('NodeList' in window && !NodeList.prototype.forEach) {
        console.info('polyfill for IE11');
        NodeList.prototype.forEach = function (callback, thisArg) {
          thisArg = thisArg || window;
          for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
          }
        };
      }

})(window);

// youtube api
let tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

history.scrollRestoration = "manual";
window.scrollTo(0,0);

import Rgpd from'./modules/rgpd';
Rgpd(cat => {
   /*console.log('cookies ' + cat + ' enabled');
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    ga('create', ParamsData.gtag_key, 'auto');
    ga('send', 'pageview');*/
 
    if(cat == 'Statistiques'){
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MBSTSXB');
    }
 });

 
import Accessibility from'./modules/accessibility';
import Smoothscroll from'./modules/smoothScroll';


// if accesspage

if(ParamsData.accessibilty){
    Accessibility();
}else if(document.body.classList.contains('home')){
    Smoothscroll();
}

if (document.querySelector(".quick-access")) {
    QuickAccess();
}

// carousel 
import Swiper from'./modules/swiper';
const carousel = document.querySelector('.carousel');

if(carousel) {
    const swiper_prev = document.querySelector('.swiper-prev');
    const swiper_next = document.querySelector('.swiper-next');
    const swiper_open = document.querySelector('.swiper-open');
    const swiper = new Swiper ('.swiper-container', {
        spaceBetween: window.innerWidth < 768 ? 20: 150,
        //mousewheel: true,
        //roundLengths: true
    });

    var isOpen = false;
    var slidenum = 0;
    const swiperStatus = () => {
        const length = swiper.slides.length - 1;
        if(ParamsData.accessibilty){
            console.log("param access");
            if(slidenum>=length) {
                slidenum = length;
                swiper_next.style.visibility = "hidden";
            }else{
                swiper_next.style.visibility = "visible";
            }
            if (slidenum <= 0) {
                swiper_prev.style.visibility = "hidden";
            } else {
                swiper_prev.style.visibility = "visible";
            }
        }
        if (slidenum < 0 && swiper_open){
            carousel.classList.remove('active');
            isOpen = false;
            if(ParamsData.accessibilty){
                setTimeout(()=>{
                    swiper_open.style.display = "block";
                },5)
            }
        }
        if(slidenum<0) slidenum = 0;
    }
    if (swiper_prev) {
        swiper_prev.onclick = () => {
            swiper.slidePrev();
            enableActiveSlide(swiper)
            slidenum--;
            swiperStatus();
        }
    }
    if (swiper_open) {
        swiper_open.onclick = () => {
            swiper_prev.style.visibility = "visible";
            carousel.classList.add('active');
            isOpen = true;
            swiper_open.style.display = "none";
        }
    }
    if (swiper_next) {
        swiper_next.onclick = () => {
            swiper.slideNext();
            enableActiveSlide(swiper)
            slidenum++;
            swiperStatus();
        }
    }
    if (swiper) {
        enableActiveSlide(swiper);
    }
}
function enableActiveSlide(swiper) {
    if(ParamsData.accessibilty) {
        for (let index = 0; index < swiper.slides.length; index++) {
            const slide = swiper.slides[index];
            slide.style.visibility = "visible";
            setTimeout(() => slide.style.visibility = index === swiper.activeIndex ? "visible" : "hidden", 1000);
        }
    }
}

// video 
import PopinLMedia from'./modules/popinMedia';
document.querySelectorAll('.media[data-video]').forEach(item => {
    item.onclick = () => PopinLMedia('video', item.dataset.video, item.dataset.videoName, item.dataset.close);
});
document.querySelectorAll('.media[data-photo]').forEach(item => {
    item.onclick = () => PopinLMedia('photo', item.dataset.photo, item.dataset.photoName, item.dataset.close, item.dataset.desc, item.dataset.popinName);
});

// popin
import Popin from'./modules/popin';
document.querySelectorAll('.hasPopin').forEach(item => {
    item.onclick = e => {
        e.preventDefault();
        Popin(item.nextElementSibling.innerHTML, item.dataset.popinName, item.dataset.close);
    };
});

// expander
document.querySelectorAll('.expander').forEach(item => {
    const expander = new Expander(item);
});







import * as conditioner from 'conditioner-core/conditioner-core.min';

document.documentElement.className = 'js';

conditioner.addPlugin({
    // the plugin "monitor" hook
    // use in html : <div data-module="modules/component.js" data-context="@visible true"></div>
    monitor: {
    // the name of our monitor, not prefixed with "@"
        name: 'visible',

        // the monitor factory method, this will create our monitor
        create: (context, element) => ({
            // current match state
            matches: false,

            // called by conditioner to start listening for changes
            addListener(change) {
                new IntersectionObserver((entries) => {
                    // update the matches state
                    this.matches = entries.pop().isIntersecting === context;

                    // inform conditioner of the new state
                    change();
                }).observe(element);
            },
        }),
    },

    // converts module aliases to paths
    moduleSetName: (name) => `./modules/${name}.js`,

    // get the module constructor
    moduleGetConstructor: (module) => module.default,

    // override the import (this makes webpack bundle all the dynamically included files as well)
    // https://webpack.js.org/api/module-methods/#import-
    // - set to "eager" to create a single chunk for all modules
    // - set to "lazy" to create a separate chunk for each module
    moduleImport: (name) => import(/* webpackMode: "lazy" */ `${name}`),
});

conditioner.hydrate(document.documentElement);
