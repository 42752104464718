
import Swiper from './swiper';
import QuickAccess from './quickAccess';
import Expander from './expander';
import VideoPlayer from './videoPlayer';

function Accessibility(){
    document.body.classList.add('accessibility');
    var wh, ww;
    

    const onresize = () => {
        wh = window.innerHeight;
        ww = window.innerWidth;
    }

    QuickAccess();

    window.addEventListener('resize', onresize, { passive: true });
    onresize();

    function nextQuestion(){
        var siteFirst = 0;
        var siteSecond = 0;
        var inc = 3;
        var percent = 0;

        answers.forEach(a => {
            if (a.classList.contains('active')) {
                siteFirst += parseInt(a.dataset.siteFirst);
                siteSecond += parseInt(a.dataset.siteSecond);
            }
        });

        const card_first = document.querySelector('.card-result.result-1');
        const card_second = document.querySelector('.card-result.result-2');
    
        console.log("siteFirst",siteFirst);
        console.log("siteSecond",siteSecond);
    
        card_first.classList.remove('active');
        card_second.classList.remove('active');

        if (siteFirst > siteSecond) {
            card_result[0].classList.add('active');
            percent = Math.floor((100 / (siteSecond + siteFirst)) * siteFirst);
            result1_percent.innerHTML = percent + "%";
            result2_percent.innerHTML = 100-percent+ "%";
        }
        if (siteFirst < siteSecond) {
            card_result[1].classList.add('active');
            percent = Math.floor((100 / (siteSecond + siteFirst)) * siteSecond);
            result1_percent.innerHTML = 100-percent+ "%";
            result2_percent.innerHTML = percent+ "%";
        }
        if(siteFirst == siteSecond && siteFirst != 0 &&  siteSecond != 0){
            percent = 50;
            result1_percent.innerHTML = 50+ "%";
            result2_percent.innerHTML = 50+ "%";
        }

        var isComplete = checkIfAllAnswered();
        if (isComplete) inc = 3;
        else inc = 0;

        if(inc == 0){
            document.querySelector('.question-result').classList.add('isNochoice');
            document.querySelector('.block-result').classList.add('isNochoice');
            document.querySelector('.question-result').classList.remove('isMiddlechoice');
            document.querySelector('.block-result').classList.remove('isMiddlechoice');
            document.querySelector('.question-result').classList.remove('choice');
            document.querySelector('.block-result').classList.remove('choice');
        }
        else if(siteFirst == siteSecond){
            document.querySelector('.question-result').classList.remove('isNochoice');
            document.querySelector('.block-result').classList.remove('isNochoice');
            document.querySelector('.question-result').classList.add('isMiddlechoice');
            document.querySelector('.block-result').classList.add('isMiddlechoice');
            document.querySelector('.question-result').classList.remove('choice');
            document.querySelector('.block-result').classList.remove('choice');
        }
        else{
            document.querySelector('.question-result').classList.remove('isNochoice');
            document.querySelector('.block-result').classList.remove('isNochoice');
            document.querySelector('.question-result').classList.remove('isMiddlechoice');
            document.querySelector('.block-result').classList.remove('isMiddlechoice');
            document.querySelector('.question-result').classList.add('choice');
            document.querySelector('.block-result').classList.add('choice');
        }


    }

    function animateNumber(value, el){
        value = parseInt(value);
        var inc = 0;
        var int;
        clearInterval(int);
        int = setInterval(()=>{
            inc++;
            if(inc >= value) clearInterval(int);
            el.innerHTML = inc + "%";
        }, 40)

    }

    const header = document.querySelector('header[role="banner"]');
    const header_btn_contact = header.querySelector('.cta-contact');
    const header_logo = header.querySelector('.logo-ca');
    const nav_bullet = document.querySelector('.nav-bullet');

    var isAnswer1 = false;
    var isAnswer2 = false;
    var isAnswer3 = false;

    // btn answer next
    const btn_next = document.querySelectorAll('.block-question .btn-next');
    btn_next.forEach(btn => {
        btn.addEventListener('click', () => {
            var tempo = 320;
            var num = 0;

            var hh = document.querySelector('.question-result');
            num ++;
            // parti pris : on passe au footer/reponses quoiqu'il arrive
            var coord = getCoords(hh);
            setTimeout(() => window.scrollTo(0, coord.top));
            hh.focus();

            hh.setAttribute("role", "alert");
            setTimeout(() => {
                hh.removeAttribute("role");
            }, 20000);

            nextQuestion();
        })
    });

    // btn header goto footer
    header_btn_contact.onclick = e => {
        e.preventDefault();
        isAnswer1 = true;
        isAnswer2 = true;
        isAnswer3 = true;
        nextQuestion();

        let question3 = document.querySelector('.question-3');
        let footerTop = question3.offsetTop + question3.querySelector('.answers').offsetHeight;
        window.scrollTo(0, footerTop);
    }

    header_logo.onclick = e =>  window.scrollTo(0,  0)

    //nav-bullet
    const nav_bullet_btns = document.querySelectorAll('.nav-bullet button')
    const nav_bullet_lis = document.querySelectorAll('.nav-bullet li');

    function nav_bullet_change(num, appear){
        const btn = nav_bullet_btns[num];

        nav_bullet_btns.forEach(bullet => {
            bullet.style.width = 40 + 'px';
            bullet.classList.remove('active')
        });
        btn.classList.add('active');

        if(appear == true){
            btn.style.width =  btn.querySelector('.btn-container').offsetWidth + 'px';
            setTimeout(() => {
                btn.style.width = 40 + 'px';
            }, 2500);
        }
    }





    function showBtnTitle(btn, index) {
        btn.style.width = btn.querySelector('.btn-container').offsetWidth + 'px';
        btn.classList.add('active');
    }
    function hideBtnTitle(btn, index) {
        btn.style.opacity = 1;

        btn.style.width = 40 + 'px';
        btn.classList.remove('active');
    }

    document.body.addEventListener('keydown', function(e) {
        if(e.key == "Escape") {
            nav_bullet_btns.forEach(function (btn, index) {
                hideBtnTitle(btn, index);
            })
        }
    })

    nav_bullet_btns.forEach(function (btn, index) {
        if (btn.classList.contains('active')) {
            btn.style.width = btn.querySelector('.btn-container').offsetWidth + 'px';    
        }

        btn.onfocus = function () {
            showBtnTitle(btn, index);
        }

        btn.onblur = function () {
            hideBtnTitle(btn, index);
        }

        btn.onmouseenter = function () {
            showBtnTitle(btn, index);
        };

        btn.onmouseleave = function () {
            hideBtnTitle(btn, index);
        };

        btn.onclick = () => {
            const num = btn.dataset.num;
            var offset;    
            var elt;    
            if(num == 0){
                elt = document.querySelector('.hero');
                offset = elt.offsetTop;
            }
            if(num == 1){
                elt = document.querySelector('.block-introduction-together');
                offset = elt.offsetTop;
                isAnswer1 = true;
            }
            if(num == 2){
                elt = document.querySelector('.block-introduction-horizon');
                offset = elt.offsetTop;
                isAnswer1 = true;
                isAnswer2 = true;
            }
            if(num == 3){
                // document.querySelector('.question-result')
                elt = document.querySelector('.question-1');
                offset = elt.offsetTop;
                isAnswer1 = true;
                isAnswer2 = true;
                isAnswer3 = true;
            }

            nextQuestion();
            window.scrollTo(0, offset);
            elt.focus();
        }
    });


    // Animations
    const question3 = document.querySelector('.question-3');
    const question3_sticky = question3.querySelector('.sticky');
    const question3_question = question3.querySelector('.question');
    const question3_answers = question3.querySelector('.answers');
    const question3_footer = question3.querySelector('footer');
    const question3_footer_percent = question3_footer.querySelector('.percent');


    var off;


    // quizz
    const answers = document.querySelectorAll('.answer');
    const result1_percent = document.querySelector('.card-result.result-1 .percent');
    const result2_percent = document.querySelector('.card-result.result-2 .percent');
    const card_result = document.querySelectorAll('.card-result');

    // active next btn if at least 1 answer for question
    answers.forEach(answer => {
        answer.onclick = () => {
            answer.classList.toggle('active');

            const isPressed = answer.classList.contains('active');
            answer.setAttribute("aria-pressed", isPressed);
        }
    })


    // btn if nochoice
    // test si une question a au moins un answer
    var isBackBtnClicked = false;
    var isNoChoice = true;
    var nbQuestions = document.querySelectorAll('.block-question--access').length;
    document.querySelector('.back-question').onclick = () =>{
        isBackBtnClicked = true;
        
        var inc = unpdateInc();

        const el = document.querySelector('.question-'+inc);
        const elToScroll = 0 ? document.querySelector('.question-1') : el;
        window.scrollTo(0, elToScroll.offsetTop );
        elToScroll.focus();
    }

    function unpdateInc(){
        var arr = getAnswers();
        var inc = 0;

        for (let i = arr.length - 1; i >= 0; i--) {
            if (arr[i] == false) {
                inc = i + 1;
            }
        }

        return inc;
    }

    function getAnswers(){
        var arr = [];
        document.querySelectorAll('.block-question--access').forEach(el => {
            var answered = false;
            el.querySelectorAll('.answer').forEach(btn => {
                if (btn.classList.contains('active')) {
                    answered = true;
                }
            })
            arr.push(answered);
        })
        return arr;
    }

    function checkIfAllAnswered() {
        var arr = getAnswers();
        var nbQuestionAnswered = 0;

        for (let i = arr.length - 1; i >= 0; i--) {
            if(arr[i]) {
                nbQuestionAnswered++;
            }
        }

        return nbQuestionAnswered == nbQuestions;
    }

    // carousel together
    const carousel = document.querySelector('.carousel-together');
    const swiper_prev = document.querySelector('.swiper-prev-together');
    const swiper_next = document.querySelector('.swiper-next-together');
    const swiper = new Swiper('.swiper-container-together', {
        spaceBetween: window.innerWidth < 768 ? 20 : 150
    });
    var isOpen = false;
    var slidenum = 0;
    const swiperStatus = () => {
        const length = swiper.slides.length - 1;
        if (slidenum >= length) {
            slidenum = length;
            swiper_next.style.visibility = "hidden";
        } else {
            swiper_next.style.visibility = "visible";
        }
        if (slidenum <= 0) {
            swiper_prev.style.visibility = "hidden";
        } else {
            swiper_prev.style.visibility = "visible";
        }
        if (slidenum < 0) slidenum = 0;
    }
    swiper_prev.onclick = () => {
        swiper.slidePrev();
        enableActiveSlide(swiper)

        slidenum--;
        swiperStatus();
    }
    swiper_next.onclick = () => {
        swiper.slideNext();
        enableActiveSlide(swiper)

        slidenum++;
        swiperStatus();
    }
    enableActiveSlide(swiper)

    function enableActiveSlide(swiper) {
        for (let index = 0; index < swiper.slides.length; index++) {
            const slide = swiper.slides[index];
            slide.style.visibility = "visible";
            setTimeout(() => slide.style.visibility = index === swiper.activeIndex ? "visible" : "hidden", 1000);
        }
    }

    function getCoords(elem) { // crossbrowser version
        var box = elem.getBoundingClientRect();

        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left) };
    }

    // expander
    // document.querySelectorAll('.expander').forEach(item => {
    //     const expander = new Expander(item);
    // });

    document.querySelectorAll(".toggle-video").forEach(item => {
        const videoToggle = new VideoPlayer(item);
    });
}


export default Accessibility;