import ToggleSubMenu from './toggleSubMenu';

function PopinMedia(type, id, title, closeLabel, desc, popinName) {
    let lastFocusedElt;
    let popinMedia;
    let lastFocus = null;
    let expandableItemId = 0;

    function openPopin() {
        lastFocusedElt = document.activeElement;

        if(type == 'video'){
            popinMedia = createPopinVideo(id, title);
        }
        if(type == 'photo'){
            popinMedia = createPopinPhoto(id, title, desc, popinName);
            document.body.style.overflow = 'hidden';
        }

        document.body.appendChild(popinMedia);

        const btn_close = popinMedia.querySelector(".btn-close");
        btn_close.onclick = () => {
            if(type == 'photo'){
                document.body.style.overflow = 'auto';
            }
            closePopin()
        };

        document.addEventListener("focus", onTrapFocus, true);

        focusFirstDescendant(popinMedia);
        
        var posterframe;
        var player;
        const onPlayerStateChange =  event => {
            if (event.data == YT.PlayerState.ENDED) {
                posterframe.classList.add('show');
            }
        };

        const onPlayerReady = (e) => {
            posterframe = popinMedia.querySelector('.posterframe');
            posterframe.onclick = () => {
                player.seekTo(0);
                posterframe.classList.remove('show');
            }
            popinMedia.querySelector('iframe').title = title;
        }
        if(type == 'video'){
            player = new YT.Player("player", {
                videoId: id,
                height: '100%',
                width: '100%',
                playerVars: {
                    mute: 1,
                    autoplay: 1,
                    controls: 1,
                    autohide: 1,
                    showinfo: 0,
                    rel: 0
                },
                events: { 
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange 
                },
            });
        }

        if(type == 'photo'){
            document.querySelectorAll('.toggle-desc').forEach(item => {
                new ToggleSubMenu(item);
            });
        }
    }

    function createPopinVideo(id, title) {
        const div = document.createElement("div");
        div.className = "popinMedia";
        div.setAttribute("role", "dialog");
        div.setAttribute("aria-modal", "true");
        div.setAttribute("aria-label", title);
        div.innerHTML =
        `<div class="window video">
            <header><button class="btn-close" aria-label="${closeLabel}"></button></header>
            <div class="window-content"> 
                <div id="player"></div>
                <div class="posterframe"></div>
            </div>
        </div>`;
        return div;
    }
    function createPopinPhoto(id, title, desc) {
        const div = document.createElement("div");
        div.className = "popinMedia";
        div.setAttribute("role", "dialog");
        div.setAttribute("aria-modal", "true");
        div.setAttribute("aria-label", popinName);
        const controlsId = `expandable-item-${expandableItemId}`;
        div.innerHTML =
        `
        <div class="window photo">
            <button class="btn-close" aria-label="${closeLabel}"></button>
            <img src="${id}" alt="${title}">
            <div class="toggle-desc">
                <button class="btn btn-toggle" aria-expanded="false" aria-controls="${controlsId}">Lire la description détaillée de l'image</button>
                <div id="${controlsId}" class="content-to-toggle"><p>${desc}</p></div>
            </div>
        </div>`;
        expandableItemId++;
        return div;
    }

    openPopin();

    function onTrapFocus(e) {
        e.stopImmediatePropagation();
        const node = popinMedia;

        if (node.contains(e.target)) {
            lastFocus = e.target;
        } else {
            focusFirstDescendant(node);

            if (lastFocus == document.activeElement) {
                focusFirstDescendant(node);
            }

            lastFocus = document.activeElement;
        }
    }

    function focusFirstDescendant(element) {
        for (let i = 0; i < element.childNodes.length; i++) {
            const child = element.childNodes[i];
            if (attemptFocus(child) || focusFirstDescendant(child)) {
                return true;
            }
        }
        return false;
    }

    function attemptFocus(element) {
        if (!isFocusable(element)) {
            return false;
        }

        try {
            element.focus();
        } catch (e) {}
        return document.activeElement === element;
    }

    function isFocusable(element) {
        if (
            element.tabIndex > 0 ||
            (element.tabIndex === 0 &&
                element.getAttribute("tabIndex") !== null)
        ) {
            return true;
        }

        if (element.disabled) {
            return false;
        }

        switch (element.nodeName) {
            case "A":
                return !!element.href && element.rel != "ignore";
            case "INPUT":
                return element.type != "hidden" && element.type != "file";
            case "BUTTON":
            case "SELECT":
            case "TEXTAREA":
                return true;
            default:
                return false;
        }
    }

    document.body.addEventListener("keydown", function (e) {
        if (e.key == "Escape") {
            closePopin();
        }
    });

    function closePopin() {
        popinMedia.remove();
        lastFocusedElt.focus();
        document.removeEventListener("focus", onTrapFocus, true);
        lastFocus = null;
    }
}

export default PopinMedia;
