function Expander(element) {
    let isSelect = isSelect;
    let isOpen = false;
    let currentZone = element.getAttribute("aria-controls");
    let closeOutTimeout, closeTimeout = -1;
    let currentTarget;

    element.addEventListener('click', (event) => {
        showZone(event);   
    })

    function showZone(event) {
        currentTarget = event.target;
        const zoneId = currentTarget.getAttribute("aria-controls");
        const elt = document.getElementById(zoneId);

        if(elt.classList.contains("active")) {
            hideAllZone();
        } else {
            currentTarget.setAttribute("aria-expanded", true);
            isOpen = true;
            const eltZone = document.getElementById(zoneId);

            elt.classList.add( "active" );

            currentTarget.addEventListener('mouseout', onElementBlur);
            currentTarget.addEventListener('focus', onElementFocus);
            currentTarget.addEventListener('blur', onElementBlur);

            const eltZoneChildren = eltZone.querySelectorAll("*");
            for (let index = 0; index < eltZoneChildren.length; index++) {
                const eltZoneChild = eltZoneChildren[index];
                
                eltZoneChild.addEventListener('mouseout', onElementBlur);
                eltZoneChild.addEventListener('focus', onElementFocus);
                eltZoneChild.addEventListener('blur', onElementBlur);
            }
        }
    }

    function onElementOver(event) {
        currentTarget = event.target;
        const zoneId = currentTarget.getAttribute("aria-controls");

        currentTarget.setAttribute("aria-expanded", true);
        isOpen = true;

        const elt = document.getElementById(zoneId);
        elt.addEventListener('mouseover', onElementIn);
        elt.addEventListener('mouseleave', onElementOut);

        currentTarget.addEventListener('mouseleave', onElementOut);
    }

    function onElementIn(event) {
        clearTimeout(closeOutTimeout);
    }

    function onElementOut(event) {
        console.log(this, event.target)
        closeOutTimeout = setTimeout(onElementOutComplete.bind(event.target), 50);
    }

    function onElementOutComplete() {
        this.setAttribute("aria-expanded", false);
        const zoneId = this.getAttribute("aria-controls");

        const elt = document.getElementById(zoneId);
        console.log(this, zoneId, elt)
        elt.removeEventListener('mouseover', onElementIn);
        elt.removeEventListener('mouseleave', onElementOut);

        this.removeEventListener('mouseleave', onElementOut);
    }

    function onElementFocus(event) {
        clearTimeout(closeTimeout);
    }

    function onElementBlur(event) {
        closeTimeout = setTimeout(hideAllZone, 30);
    }

    function hideAllZone() {
        currentTarget.setAttribute("aria-expanded", false);
        isOpen = false;

        const zoneId = currentTarget.getAttribute("aria-controls");
        const eltZone = document.getElementById(zoneId);
        eltZone.classList.remove( "active" );

        currentTarget.removeEventListener('mouseout', onElementBlur);
        currentTarget.removeEventListener('focus', onElementFocus);
        currentTarget.removeEventListener('blur', onElementBlur);

        const eltZoneChildren = eltZone.querySelectorAll("*");
        for (let index = 0; index < eltZoneChildren.length; index++) {
            const eltZoneChild = eltZoneChildren[index];
            
            eltZoneChild.removeEventListener('mouseout', onElementBlur);
            eltZoneChild.removeEventListener('focus', onElementFocus);
            eltZoneChild.removeEventListener('blur', onElementBlur);
        }
    }

    function hideZone() {
        currentTarget.setAttribute("aria-expanded", false);
        isOpen = false;
        var zoneId = currentTarget.getAttribute("aria-controls");

        const eltZone = document.getElementById(zoneId);
        TweenMax.to(eltZone, .1, {alpha: 0, display:'none', force3D: true, ease: Expo.easeIn, onComplete: function(elt) {

            eltZone.classList.remove("active");
        }, onCompleteParams: [eltZone]});
    }

}

export default Expander;