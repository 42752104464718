function ToggleSubMenu(element) {
    const subMenu = element;


    const subMenuBtn = subMenu.querySelector('.btn-toggle');;

    function toggle(event) {
        const button = event.target;

        if (!subMenu.classList.contains('open')) {
            subMenu.classList.add('open');

            button.setAttribute("aria-expanded", true);
        } else {
            subMenu.classList.remove('open');
            button.setAttribute("aria-expanded", false);
        }
    }

    subMenuBtn.addEventListener('click', toggle);

}

export default ToggleSubMenu;
