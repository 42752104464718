import ToggleSubMenu from './toggleSubMenu';

function PopinMedia(content, title, closeLabel) {
    let lastFocusedElt;

    let popinMedia;

    let lastFocus = null;

    function openPopin() {
        lastFocusedElt = document.activeElement;

        popinMedia = createPopinContent(content, title);
        document.body.appendChild(popinMedia);

        const btn_close = popinMedia.querySelector('.btn-close');
        btn_close.onclick = () => {
            closePopin();
        }

        if (document.querySelector(".toggle-desc")) {
            document.querySelectorAll('.toggle-desc').forEach(item => {
                new ToggleSubMenu(item);
            });
        }

    	document.addEventListener('focus', onTrapFocus, true);

		focusFirstDescendant(popinMedia);
    }

    function createPopinContent(content, title) {
        const div = document.createElement("div");
        div.className = 'popin';
        div.setAttribute('role', 'dialog');
        div.setAttribute('aria-modal', 'true');
        div.setAttribute('aria-label', title);
        div.innerHTML = `
        <div class="window">
            <button class="btn-close" aria-label="` + closeLabel + `"></button>
            <div class="window-content"> 
                ${content}
            <div>
        </div>`;
        return div;
    }

    openPopin();


	function onTrapFocus(e) {
        e.stopImmediatePropagation();
        const node = popinMedia;

		if (node.contains(e.target)) {
			lastFocus = e.target;
		} else {
			focusFirstDescendant(node);

			if (lastFocus == document.activeElement) {
				focusFirstDescendant(node);
			}

			lastFocus = document.activeElement;
		}
	}

    function focusFirstDescendant(element) {
		for (let i = 0; i < element.childNodes.length; i++) {
		  const child = element.childNodes[i];
		  if (attemptFocus(child) ||
		      focusFirstDescendant(child)) {
		    return true;
		  }
		}
		return false;
	};

	function attemptFocus(element) {
		if (!isFocusable(element)) {
			return false;
		}

		try {
			element.focus();
		}
		catch (e) {
		}
		return (document.activeElement === element);
	};

    function isFocusable(element) {
		if (element.tabIndex > 0 || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)) {
		    return true;
		}

		if (element.disabled) {
		    return false;
		}

		switch (element.nodeName) {
            case 'A':
                return !!element.href && element.rel != 'ignore';
            case 'INPUT':
                return element.type != 'hidden' && element.type != 'file';
            case 'BUTTON':
            case 'SELECT':
            case 'TEXTAREA':
                return true;
            default:
                return false;
		}
	}

    document.body.addEventListener('keydown', function(e) {
        if(e.key == "Escape") {
            closePopin();
        }
    });

    function closePopin() {
        popinMedia.remove();
        lastFocusedElt.focus();
    	document.removeEventListener('focus', onTrapFocus, true);
        lastFocus = null;
    }

    

}

export default PopinMedia;
