function Smoothscroll(){

var wh;
var ww = window.innerWidth
var posY;
var isMobile = ww < 768 ? true: false;
var oldisMobile;
var result1_nb = 0;
var result2_nb = 0;

//const iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
//if(iOS)  document.body.classList.add('ios');

function SmoothScroll(onscroll){
    const el = document.querySelector('[data-scroll]');
    const content = document.querySelector('[data-scroll-content]');
    const ease = .08;
    const raf = requestAnimationFrame || webkitRequestAnimationFrame;
    const caf = cancelAnimationFrame || webkitCancelAnimationFrame;
	var current = 0;
	var last = 0;
    var rounded = 0;
    var isDisabled = false;
    var sY;

    const scroll = () => current =  window.pageYOffset;
    const scrollMobile = () => {   
        posY = window.pageYOffset;
        onscroll();
    };

    const onresize = () => {
        if(!isMobile) document.body.style.height = `${content.clientHeight}px`;
        wh = window.innerHeight;
        ww = window.innerWidth;
        scroll()
    }
 
    const run = () => {
        if(isDisabled){	
            if(current >= sY) {
                current = sY;  
                window.scrollTo(0, sY); 
                header.classList.add('hideDisabled');
            }else{
                header.classList.remove('hideDisabled');
            }
        } 
        last += (current - last) * ease;
        rounded = Math.round(last * 100) / 100;
        content.style.transform = `translate(0, ${-rounded}px)`;
        posY = rounded;
        onscroll(rounded);
        raf(run);
    }

    this.disable = val => {
        isDisabled = true;
        sY = val;
    }
    this.enable = () => {
        header.classList.remove('hideDisabled');
        isDisabled = false;
    } 
    this.reset = () => onresize();
    
    this.switch = () => {
        if(isMobile){
            caf(run);
            document.body.removeAttribute('style');
            window.removeEventListener('scroll', scroll, {passive: true});
            window.addEventListener('scroll', scrollMobile, {passive: true});
        } else{
            raf(run);
            Object.assign(el.style, {
                position: 'fixed',
                height: '100%',
                overflow: 'hidden'        
            })
            window.removeEventListener('scroll', scrollMobile, {passive: true});
            window.addEventListener('scroll', scroll, {passive: true});
        }
        onresize();
    };

    window.addEventListener('resize', onresize, {passive: true});

    window.addEventListener('load', onresize, {once: true});
   
};

const anim = (percent, start, end, func) => {
    var value = 0;
    if(percent > start) value = (percent - start) / (end - start);
    if(value >= 1) value = 1;
    func(value);
}
const anim1 = (percent, start, end, func) => {
    if(percent > start) {
        var value = (percent - start) / (end - start);
        if(value >= 1) value = 1;
        func(value);
    }
}
const toggle = (percent, start, enter) => {
    let oldonce;
    const once = percent > start ? true : false;
    once && (oldonce != once) && enter();
    oldonce = once;
}
var toogl = {
    percent: null,
    today: null,
    moment: null,
    start_here: null,
    guide: null,
    win: null,
    agissons_pour: null,
    agissons:null,
    start_here1:null
}
const togglea = (percent, start, trig,  enter) => {
    const oncea = percent > start ? true : false;
    (toogl[trig] != oncea) && enter(oncea);
    toogl[trig] = oncea;
}
const section = (el, inside) => {
    const elH = el.clientHeight; 
    const percent = -(posY - el.offsetTop - elH) / (wh + elH);
    (percent > 0 && percent < 1) && inside(100 * (1-percent));
}
const topTobottom = (el, inside) => {
    var percent = -(posY - el.offsetTop) / (wh - el.clientHeight);
    
    if(percent >= -0.4 && percent <= 1.4) {
        if(percent <= 0){
            percent = 0;
        }
        if(percent >= 1){
            percent = 1;
        }
       inside(100 * percent);
    }
}
const crossfade = (translate, width, elw, begin, end) => {
    const duration = isMobile ? 150 : 160;
    const offset = isMobile ? 35 : 170;
    var value = 0;
    var posX = translate + width;
    var under = true;
     
    if(posX <= duration && posX >= 0){
        value = -(posX - duration) / duration;
    }
    if(posX < 0) value = 1;
    if(posX < -elw + duration + offset && posX >= -elw - offset){
        value = (posX + elw - offset) / duration;
        under = false
    }
    if(posX< -elw - offset) value = 0;
    under == true ? begin(value) : end(value);
}

// next question
function nextQuestion(){
    var siteFirst = 0;
    var siteSecond = 0;
    var inc = 3;
    var percent = 0;

    answers.forEach(a => {
        if(a.classList.contains('active')) {
            siteFirst += parseInt(a.dataset.siteFirst);
            siteSecond += parseInt(a.dataset.siteSecond);
        }
    });

    const card_first = document.querySelector('.card-result.result-1');
    const card_second = document.querySelector('.card-result.result-2');

    console.log("siteFirst",siteFirst);
    console.log("siteSecond",siteSecond);

    card_first.classList.remove('active');
    card_second.classList.remove('active');

    if(siteFirst > siteSecond){
        card_first.classList.add('active');
        percent = Math.floor((100 / (siteSecond+siteFirst)) * siteFirst);
        result1_percent.innerHTML = percent;
        result2_percent.innerHTML = 100-percent;
        result1_nb = percent;
        result2_nb = 100-percent;
    } 
    if(siteFirst < siteSecond){
        card_second.classList.add('active');
        percent = Math.floor((100 / (siteSecond+siteFirst)) * siteSecond);
        result1_percent.innerHTML = 100-percent;
        result2_percent.innerHTML = percent;
        result1_nb = 100-percent;
        result2_nb = percent;
    }
    if(siteFirst == siteSecond && siteFirst != 0 &&  siteSecond != 0){
        percent = 50;
        result1_percent.innerHTML = 50;
        result2_percent.innerHTML = 50;
        result1_nb = 50;
        result2_nb = 50;
    }

    btn_next.forEach(e => {
        if(!e.classList.contains('active')) inc = 0;
    })

    if(inc == 0){
        document.querySelector('.question-result').classList.add('isNochoice');
        document.querySelector('.block-result').classList.add('isNochoice');
        document.querySelector('.question-result').classList.remove('isMiddlechoice');
        document.querySelector('.block-result').classList.remove('isMiddlechoice');
        document.querySelector('.question-result').classList.remove('choice');
        document.querySelector('.block-result').classList.remove('choice');
    }
    else if(siteFirst == siteSecond){
        document.querySelector('.question-result').classList.remove('isNochoice');
        document.querySelector('.block-result').classList.remove('isNochoice');
        document.querySelector('.question-result').classList.add('isMiddlechoice');
        document.querySelector('.block-result').classList.add('isMiddlechoice');
        document.querySelector('.question-result').classList.remove('choice');
        document.querySelector('.block-result').classList.remove('choice');
    }
    else{
        document.querySelector('.question-result').classList.remove('isNochoice');
        document.querySelector('.block-result').classList.remove('isNochoice');
        document.querySelector('.question-result').classList.remove('isMiddlechoice');
        document.querySelector('.block-result').classList.remove('isMiddlechoice');
        document.querySelector('.question-result').classList.add('choice');
        document.querySelector('.block-result').classList.add('choice');
    }
    
    console.log("siteFirst",siteFirst);
    console.log("siteSecond",siteSecond);
    console.log("percent",percent);

    smoothscroll.reset();
}

function tilteSvg(id, stokeWidth, onfinished){
    const el = document.querySelector(`#${id}`);
    var inc = 0;

    el.querySelectorAll('.title-mask').forEach((line, i) => {
        const length = line.getTotalLength();
        line.style.strokeWidth = stokeWidth;
        line.style.animationName = 'title-svg';
        line.style.animationFillMode = 'forwards';
        line.style.animationTimingFunction = 'linear';
        line.style.strokeDasharray = length + ' ' + length;
        line.style.strokeDashoffset = length;
        line.style.animationDelay = (inc) +'s';
        line.style.animationDuration = (length/1000) +'s';
        inc = inc + length/1000;
    });

    setTimeout(() => {
        if(typeof onfinished == 'function') onfinished();
    }, inc * 1000);
}

function animateNumber(value, el){
    value = parseInt(value);
    console.log(el,value);
    var inc = 0;
    var int;
    clearInterval(int);
    int = setInterval(()=>{
        inc++;
        if(inc >= value) clearInterval(int);
        el.innerHTML = inc + "%";
    }, 40)

}

// add class for will-change if on viewport
const reveal = el => {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            entry.isIntersecting ? el.classList.add("viewed") : el.classList.remove("viewed")
        });
    });
    observer.observe(el);
}

//
const header = document.querySelector('header[role="banner"]');
const header_btn_contact = header.querySelector('.cta-contact');
const header_logo = header.querySelector('.logo-ca');
const nav_bullet = document.querySelector('.nav-bullet');
var isAnswer1 = false;
var isAnswer2 = false;
var isAnswer3 = false;
var oldindexTogether = null;

// btn answer next
const btn_next = document.querySelectorAll('.block-question .btn-next');
btn_next.forEach(btn => {
    btn.addEventListener('click', () => {
        const sticky =  btn.closest('section').querySelector('.sticky');
        var tempo = 0;
        var num = 0;
        if(btn.closest('section').classList.contains('question-1')) {
            isAnswer1 = true;
            sticky.classList.add('transition');
            tempo = 320;
            num = 1;
        } 
        if(btn.closest('section').classList.contains('question-2')) {
            isAnswer2 = true;
            sticky.classList.add('transition');
            tempo = 320;
            num = 2;
        } 
        if(btn.closest('section').classList.contains('question-3')) {
            isAnswer3 = true;
            sticky.classList.add('transition');
            tempo = 320;
            num = 3;
        }
        smoothscroll.enable();

        var hh = btn.closest('section').nextElementSibling;
        var offset = hh.offsetTop - window.innerHeight;
        num ++;

        //
        var isok = true;
        btn_next.forEach((e, i) => {
            if(!e.classList.contains('active')) isok = false;
        })

        if(isBackBtnClicked == true && num <4){
            hh = document.querySelector('.question-'+ num);
            offset = hh.offsetTop;
        }
        if(isok ){
            hh = document.querySelector('.question-3');
            if(isMobile) offset = hh.offsetTop+document.querySelector('.question-3').clientHeight-wh;
            else offset = hh.offsetTop+wh;
        }
        setTimeout(() => window.scrollTo(0, offset), tempo);

        nextQuestion();
    })
});


// btn scroll
const btn_scroll = document.querySelector('.hero .btn-scroll');
btn_scroll.onclick = () => window.scrollTo(0, wh);

// btn header goto footer
header_btn_contact.onclick = e => {
    e.preventDefault();
    isAnswer1 = true;
    isAnswer2 = true;
    isAnswer3 = true;
    smoothscroll.enable();
    nextQuestion();
    
    window.scrollTo(0,  document.querySelector('.question-3').offsetTop + wh);
}

header_logo.onclick = e =>  window.scrollTo(0,  0)

//nav-bullet
const nav_bullet_btns = document.querySelectorAll('.nav-bullet button')
function nav_bullet_change(num, appear){
    const btn = nav_bullet_btns[num];
    nav_bullet_btns.forEach(bullet => {
        bullet.style.width = 40 + 'px';
        bullet.classList.remove('active')
    });
    btn.classList.add('active'); 

    if(appear == true){
        btn.style.width =  btn.querySelector('.btn-container').offsetWidth + 'px';
        setTimeout(() => {
            btn.style.width = 40 + 'px';
        }, 2500);
    }
}
nav_bullet_btns.forEach((btn, index) => {
    if(btn.classList.contains('active')){
        btn.style.width =  btn.querySelector('.btn-container').offsetWidth + 'px';
    }
    btn.onmouseenter = () => {
        btn.style.width =  btn.querySelector('.btn-container').offsetWidth + 'px';
    }
    btn.onmouseleave = () => {
        btn.style.opacity = 1;
        btn.style.width = 40 + 'px';
    }
    btn.onclick = () => {
        const num = btn.dataset.num;
        var offset;    
        if(num == 0){
            offset = document.querySelector('.hero').offsetTop;
        }
        if(num == 1){
            offset = document.querySelector('.question-1').offsetTop + 3 * wh;
            isAnswer1 = true;
        }
        if(num == 2){
            offset = document.querySelector('.question-2').offsetTop + wh;
            isAnswer1 = true;
            isAnswer2 = true;
        }
        if(num == 3){
            offset = document.querySelector('.question-3').offsetTop + wh;
            isAnswer1 = true;
            isAnswer2 = true;
            isAnswer3 = true;
        }
        smoothscroll.enable();
        nextQuestion();
        window.scrollTo(0, offset);
    }
});


// splash screen & hero animation
const splashscreen = document.querySelector('.splash-screen');
const splashscreen_hashtag = splashscreen.querySelector('.hashtag');
window.onload = () => {
    tilteSvg('title-svg-splash', 8, () => {
        splashscreen_hashtag.classList.add('anim');
        setTimeout(() => {
            splashscreen.classList.add('hide');
            splashscreen.addEventListener('transitionend', () => {
              splashscreen.remove();
                document.querySelector('.hero').classList.add('anim');
                setTimeout(() => {
                    tilteSvg('title-svg-adventure', 8, () => {
                        document.querySelector('.hero').classList.add('next');
                    });
                }, 500);
            },{once:true});
        }, 900)
    });  
}

// Animations
const hero = document.querySelector('.hero');
const intro = document.querySelector('.block-intro');
const intro_sticky = intro.querySelector('.sticky');
const intro_header = intro.querySelector('header');
const intro_content = intro.querySelector('.content');
const block_avantage = document.querySelector('.block-avantage');
const block_avantage_title = block_avantage.querySelector('.title-svg');
const question1 = document.querySelector('.question-1');
const question1_sticky = question1.querySelector('.sticky');
const question1_header = question1.querySelector('header');
const question1_question = question1.querySelector('.question');
const question1_answers = question1.querySelector('.answers');
const question1_footer = question1.querySelector('footer');
const together = document.querySelector('.block-together');
const together_sticky = together.querySelector('.sticky');
const together_titles = together.querySelector('.titles ul');
const together_titles_lis = together_titles.querySelectorAll('li');
const together_slides = together.querySelector('.slides');
const together_slides_lis = together_slides.querySelectorAll('li');
const horizon = document.querySelector('.block-horizon');
const horizon_sticky = horizon.querySelector('.visu');
const horizon_img = horizon.querySelector('.visu img');
const question2 = document.querySelector('.question-2');
const question2_sticky = question2.querySelector('.sticky');
const question2_question = question2.querySelector('.question');
const question2_answers = question2.querySelector('.answers');
const question2_footer = question2.querySelector('footer');
const question3 = document.querySelector('.question-3');
const question3_sticky = question3.querySelector('.sticky');
const question3_question = question3.querySelector('.question');
const question3_answers = question3.querySelector('.answers');
const question3_footer = question3.querySelector('footer');

const reset = () => {
    intro_header.style = '';
    intro_content.style = '';
    block_avantage_title.style = '';
    question1_header.style = '';
    question1_question.style = '';
    question1_answers.style = '';
    question1_footer.style = '';
    together_titles.style = '';
    together_slides.style = '';
    horizon_img.style = '';
    question2_question.style = '';
    question2_answers.style = '';
    question2_footer.style = '';
    question3_question.style = '';
    question3_answers.style = '';
    question3_footer.style = '';
}
var off;
const stick = (container, el) => {
    off = posY - el.offsetTop;
    if(off <= 0) off = 0;
    if(off > container.offsetHeight - el.offsetHeight) off = container.offsetHeight - el.offsetHeight;
    el.style.transform = `translateY(${off}px)`;
}

const anim_mobile = () => {
    section(question3, percent => {
        togglea(percent, 70,'percent' , (status) => {
            nav_bullet.classList.add('dark');
            if(status) {
                animateNumber(result1_nb, result1_percent);
                animateNumber(result2_nb, result2_percent);
                question3_footer.classList.add('display');
            }
            else question3_footer.classList.remove('display'); 
        });
        togglea(percent, 50,'guide', status => status && tilteSvg('title-svg-guide', 8));
        toggle(percent, 33, () => isAnswer3 == false && smoothscroll.disable(question3.offsetTop));
    });

    section(horizon, percent => {
        anim(percent, 5, 25, val => {horizon_img.style.transform = `scale(${0.6 + val * .4})`;});
   });

    section(question2, percent => {
        togglea(percent, 45,'start_here1', status => status && tilteSvg('title-svg-start_here1', 8));
        toggle(percent, 33, () => isAnswer2 == false && smoothscroll.disable(question2.offsetTop));
    });

    section(together, percent => {
        togglea(percent, 5,'agissons_pour', status => status && tilteSvg('title-svg-agissons_pour', 3.5));
        const length = together_slides_lis.length;
		var translate;
		var incwidth = 0;
        var currentwidth;
        var indexTogether = 0;
		anim(percent, 20, 66, val => {
			translate = -val * (together_titles.offsetWidth);
            together_titles.style.transform = `translateX(${translate}px)`;
        });
		for(let i = 0; i < length; i++){
            currentwidth = together_titles_lis[i].clientWidth;
			crossfade(translate, incwidth, currentwidth,
				val => {
                    together_slides_lis[i].style.opacity = 1;
                    together_slides_lis[i].style.transform = `translate(${100 - val * 100}%,0)`;
				}, 
				val => {
                    together_slides_lis[i].style.opacity = 1;
                    together_slides_lis[i].style.transform = `translate(${-100 + val*100}%,0)`;
				}
            );
            incwidth += currentwidth;
            if(translate+together_titles_lis[i].offsetLeft-100<0){
                indexTogether++;
            }
        }
        if(oldindexTogether != indexTogether){
            together_titles_lis.forEach((li, num) => {
                li.classList.remove('active');
                together_slides_lis[num].classList.remove('active');
            });
            together_titles_lis[indexTogether-1].classList.add('active');
            together_slides_lis[indexTogether-1].classList.add('active');
        }
        oldindexTogether = indexTogether;
    });

    section(question1, percent => {
        toggle(percent, 50, () =>  isAnswer1 == false && smoothscroll.disable(question1.offsetTop +  wh));
        togglea(percent, 73,'agissons', status => status && tilteSvg('title-svg-agissons', 3.5));
        anim(percent, 25, 50, val => question1_header.style.height = `${100-val*100}vh`);
    });

    section(intro, percent => {
        togglea(percent, 25,'today', status => status && tilteSvg('title-svg-today', 8));
        togglea(percent, 50, 'moment', status => status && tilteSvg('title-svg-start_here', 8));
        anim(percent, 100/3, 100-100/3, val => intro_header.style.height = `${100-val*100}vh`);
    });
}

const anim_desktop = () => {

    section(question3, percent => {
        stick(question3, question3_sticky);
        toggle(percent, 100/3,() => {
            nav_bullet.classList.add('dark');
            header.classList.add('dark');
        });
        togglea(percent, 41, 'navbulletfirst', status => status ? nav_bullet_change(3, true) :nav_bullet_change(2, false));
        toggle(percent, 42,() => {
            header.classList.remove('dark');
            nav_bullet.classList.remove('dark');
        });
        togglea(percent, 25,'guide', status => status && tilteSvg('title-svg-guide', 8));
        togglea(percent, 60,'percent' , status => {
            nav_bullet.classList.add('dark');

            if(status) {
                animateNumber(result1_percent.innerHTML, result1_percent);
                animateNumber(result2_percent.innerHTML, result2_percent);
                question3_footer.classList.add('display');
            }
            else question3_footer.classList.remove('display'); 
        });
        toggle(percent, 80,() => nav_bullet.classList.add('dark'));
        toggle(percent, 100/3,() => isAnswer3 == false && smoothscroll.disable(question3.offsetTop));
        
        anim(percent, 100/3 + 5, 2*100/3, val => question3_question.style.transform = `translate(-${val*100}%,0)`);
        anim(percent, 100/3 + 5, 2*100/3, val => question3_answers.style.left = `${50-val*100}%`);
        anim(percent, 100/3 + 5, 2*100/3, val => question3_footer.style.left = `${50-val*50}%`);

    });

    topTobottom(horizon, percent => {
        header.classList.add('dark');
        stick(horizon, horizon_sticky);
        //horizon_img.style.height = horizon.offsetHeight + "px";
        anim(percent, 0, 100, val => horizon_img.style.transform = `scale(${ .6 + val*.4})`);

       // anim(percent, 0, 100, val => horizon_img.style.height = `${30+val*70}%`);
    });

    section(question2, percent => {
        stick(question2, question2_sticky);
        toggle(percent, 100/3,() => {
            nav_bullet.classList.add('dark');
            header.classList.add('dark');
        });
        togglea(percent, 41, 'navbulletfirst', status => status ? nav_bullet_change(2, true) :nav_bullet_change(1, false));
        toggle(percent, 42,() => {
            header.classList.remove('dark');
            nav_bullet.classList.remove('dark');
        });
        togglea(percent, 45,'start_here1', status => status && tilteSvg('title-svg-start_here1', 8));
        toggle(percent, 80,() => nav_bullet.classList.add('dark'));
        toggle(percent, 95,() => header.classList.add('dark'));
        
        toggle(percent, 100/3,() => isAnswer2 == false && smoothscroll.disable(question2.offsetTop));
        anim(percent, 100/3 + 5, 2*100/3, val => question2_question.style.transform = `translate(-${val*100}%,0)`);
        anim(percent, 100/3 + 5, 2*100/3, val => question2_answers.style.left = `${50-val*100}%`);
        anim(percent, 100/3 + 5, 2*100/3, val => question2_footer.style.left = `${50-val*50}%`);
    });

    section(together, percent => {
        togglea(percent, 5,'agissons_pour', status => status && tilteSvg('title-svg-agissons_pour', 3.5));
        toggle(percent, 100/5,() => header.classList.add('dark'));
        stick(together, together_sticky);
        const length = together_slides_lis.length;
		var translate;
		var incwidth = 0;
        var currentwidth;
        var indexTogether = 0;

		anim(percent, 20, 66, val => {
			translate = -val * (together_titles.offsetWidth);
            together_titles.style.transform = `translateX(${translate}px)`;
        });
		for(let i = 0; i < length; i++){
            currentwidth = together_titles_lis[i].clientWidth;
			crossfade(translate, incwidth, currentwidth,
				val => {
					together_slides_lis[i].style.opacity = val;
                    together_slides_lis[i].querySelector(".text").style.transform = `translate(${100 - val * 100}px,0)`;
                    together_slides_lis[i].querySelector(".media-0").style.transform = `translate(${100 - val * 100}px,0)`;
                    together_slides_lis[i].querySelector(".media-1").style.transform = `translate(${100 - val * 100}px,0)`;
				}, 
				val => {
                    together_slides_lis[i].style.opacity = val;
                    together_slides_lis[i].querySelector(".text").style.transform = `translate(${-100 + val*100}px,0)`;
                    together_slides_lis[i].querySelector(".media-0").style.transform = `translate(${-100 + val*100}px,0)`;
                    together_slides_lis[i].querySelector(".media-1").style.transform = `translate(${-100 + val*100}px,0)`;
				}
            );
            incwidth += currentwidth;
            if(translate+together_titles_lis[i].offsetLeft-100<0){
                indexTogether++;
            }
        }
        if(oldindexTogether != indexTogether){
            together_titles_lis.forEach((li, num) => {
                li.classList.remove('active');
                together_slides_lis[num].classList.remove('active');
            });
            together_titles_lis[indexTogether-1].classList.add('active');
            together_slides_lis[indexTogether-1].classList.add('active');
        }
        oldindexTogether = indexTogether;
    });

    section(question1, percent => {

        stick(question1, question1_sticky);
        toggle(percent, 15 , () => {
            header.classList.remove('dark');
        });
        toggle(percent, 25, () => {
            nav_bullet.classList.add('dark');
            header.classList.add('dark');
        });
        toggle(percent, 40, () => isAnswer1 == false && smoothscroll.disable(question1.offsetTop + wh));
        toggle(percent, 45, () =>{
            nav_bullet.classList.remove('dark');
            header.classList.remove('dark');
        });
        togglea(percent, 70, 'navbulletfirst', status => status ? nav_bullet_change(1, true) : nav_bullet_change(0, false));
        togglea(percent, 73,'agissons', status => status && tilteSvg('title-svg-agissons', 3.5));
        toggle(percent, 88, () => nav_bullet.classList.add('dark'));
        toggle(percent, 98, () => header.classList.add('dark'));
        
        anim(percent, 20, 40, val => question1_header.style.transform = `translateX(${-val*100}%)`);
        anim(percent, 20, 40, val => question1_question.style.transform = `translateX(${-val*35}%)`);
        anim(percent, 20, 40, val => question1_answers.style.left = `${100-val*50}%`);
        anim(percent, 20, 40, val => question1_footer.style.left = `${100}%`);
        
        anim1(percent, 40, 75, val => question1_answers.style.left = `${50-val*100}%`);
		anim1(percent, 40, 75, val => question1_question.style.transform = `translate(-${35+val*35}%,0)`);
        anim1(percent, 40, 75, val => question1_footer.style.left = `${60-val*60}%`);
    });

    section(block_avantage, percent => {
        toggle(percent, 100/3,() => {
            header.classList.add('dark');
            nav_bullet.classList.add('dark');
        });  
        toggle(percent, 83,() => nav_bullet.classList.remove('dark'));
        anim(percent, 0, 40, val => block_avantage_title.style.top = `${-20+val*20}%`);
    });

    section(intro, percent => {
        stick(intro, intro_sticky);
        togglea(percent, 25,'today', status => status && tilteSvg('title-svg-today', 8));
        togglea(percent, 50, 'start_here', status => status && tilteSvg('title-svg-start_here', 8));
        toggle(percent, 100/3,() => header.classList.add('dark'));
        anim(percent, 100/3, 100-100/3, val => intro_header.style.transform = `translateX(${-val*100}%)`);
        anim(percent, 100/3, 100-100/3, val => intro_content.style.transform = `translateX(${-val*35}%)`);
    });

    section(hero, percent => {
        togglea(percent, 10, 'navbulletfirst', status => status ? nav_bullet_change(0, true) : nav_bullet_change(0, false));
        toggle(percent, 0,() => {
            header.classList.remove('dark');
            nav_bullet.classList.remove('dark');
        });
        toggle(percent, 83,() => nav_bullet.classList.add('dark'));
    });
}

const smoothscroll = new SmoothScroll(() => isMobile ? anim_mobile() : anim_desktop())

// breakpoint
const breakpoint = () => {
    reset();
    smoothscroll.switch();
}
breakpoint();
window.addEventListener('resize', () => {
    isMobile = window.innerWidth < 768 ? true: false;
    oldisMobile != isMobile && breakpoint();
    oldisMobile = isMobile;
});

// quizz
const answers = document.querySelectorAll('.answer');
const result1_percent = document.querySelector('.card-result.result-1 .percent');
const result2_percent = document.querySelector('.card-result.result-2 .percent');

// active next btn if at least 1 answer for question
answers.forEach(answer => {
    answer.onclick = () => {
        answer.classList.toggle('active');
        const section = answer.closest('section');
        var active = false;
        section.querySelectorAll('.answer').forEach(el => {
            if(el.classList.contains('active')) active = true;
        });
        if(active == true){
            section.querySelector('.btn-next').classList.add('active');
        } else{
            section.querySelector('.btn-next').classList.remove('active');
        }
    }
})


// btn if nochoice
// test si une question a au moins un answer
var isBackBtnClicked = false;
document.querySelector('.back-question').onclick = () =>{
    isBackBtnClicked = true;
    var arr = [];
    document.querySelectorAll('.block-question').forEach( el => {
        var answered = false;
        el.querySelectorAll('.answer').forEach(btn => {
            if(btn.classList.contains('active')) {
                answered = true;
            } 
        })
        arr.push(answered);
    })
    
    var inc = 0;
    for(let i = arr.length - 1; i >= 0; i--){
        if(arr[i] == false){
            inc = i + 1;
        }
    }

    const el = document.querySelector('.question-'+inc);
    inc == 1 ? window.scrollTo(0, el.offsetTop + wh + wh/1000) : window.scrollTo(0, el.offsetTop);
}

// add class when blocks are on viewport to add will-change
reveal(hero);
reveal(intro); 
reveal(block_avantage);
reveal(question1);
reveal(together);
reveal(question2);
reveal(horizon);
reveal(question3);

// stop hero video if not on viewport
const hero_video = hero.querySelector('video');
const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if(entry.isIntersecting){
            hero_video.play();
        } else{
            hero_video.pause();
        }
    });
});
observer.observe(hero_video);


}

export default Smoothscroll;