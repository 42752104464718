function QuickAccess() {

    let $quickAccess = document.querySelector(".quick-access");
    let $quickAccessItem = document.querySelectorAll(".quick-item");
    let isQuickAccessFocus = false;
    let closeQuick;

    function onFocusQuickItem() {
        clearTimeout(closeQuick);

        if (!isQuickAccessFocus) {
            showQuickAccess();
        }
    }

    function onBlurQuickItem() {
        closeQuick = setTimeout(hideQuickAccess, 30);
    }

    function showQuickAccess() {
        isQuickAccessFocus = true;
        document.body.classList.add("quick-access-open");
        $quickAccess.classList.add("active");
    }

    function hideQuickAccess() {
        document.body.classList.remove("quick-access-open");
        $quickAccess.classList.remove("active");
        isQuickAccessFocus = false;
    }

    $quickAccessItem.forEach(btn => {
        btn.addEventListener('focus', (event) => {
            onFocusQuickItem();   
        })

        btn.addEventListener('blur', (event) => {
            onBlurQuickItem();   
        })
    });
}

export default QuickAccess;

