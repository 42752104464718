function VideoPlayer(element) {
    let vid = document.getElementById("video-intro");
    let playLabel = element.getAttribute("data-play");
    let pauseLabel = element.getAttribute("data-pause");

    element.addEventListener('click', togglePassword);

    function togglePassword(event) {
        event.preventDefault();
        
        if (element.classList.contains('playing')) {
            vid.pause();
            element.classList.remove("playing");
            element.classList.add("stopped");

            element.setAttribute("aria-label", playLabel);
        } else {
            vid.play();
            element.classList.remove("stopped");
            element.classList.add("playing");

            element.setAttribute("aria-label", pauseLabel);
        }
    }
}

export default VideoPlayer;
